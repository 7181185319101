import { request } from './request'

// 获取供应商列表
export function getSupplierListRequest (params) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/supplier/list',
    method: 'get',
    params
  })
}

// 更新供应商
export function updateSupplierRequest (info) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/supplier/update',
    method: 'post',
    data: { ...info }
  })
}

// 添加供应商
export function addSupplierRequest (info) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/supplier/add',
    method: 'post',
    data: { ...info }
  })
}

// 获取品牌列表
export function getBrandListRequest (params) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/brand/list',
    method: 'get',
    params
  })
}

// 获取分类列表
export function getCategoryListRequest (params) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/category/list',
    method: 'get',
    params
  })
}

// 同步品牌
export function syncBrandRequest (info) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/brand/sync',
    method: 'post',
    data: { ...info }
  })
}

// 同步分类
export function syncCategoryRequest (info) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/category/sync',
    method: 'post',
    data: { ...info }
  })
}

// 同步spu
export function syncSpuRequest (info) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/spu/sync',
    method: 'post',
    data: { ...info }
  })
}

// 增量同步spu
export function incrSyncSpuRequest (info) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/spu/incr/sync',
    method: 'post',
    data: { ...info }
  })
}

// 获取同步状态
export function getSyncStatusRequest (params) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/sync/status',
    method: 'get',
    params
  })
}

// 获取同步记录
export function getSyncRecordRequest (params) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/sync/record/list',
    method: 'get',
    params
  })
}

// 获取失败详情
export function getFailedDetailRequest (params) {
  return request({
    baseUrl: window.g.SUPPLY_URL,
    url: '/sync/failed/list',
    method: 'get',
    params
  })
}
